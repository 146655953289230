import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { css, cx } from 'emotion'

import Page from 'layouts/Page'
import { pinkRed, sepia, spaceCadet, themeBlue } from 'constants/colors'

const hero = css`
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: center;
    h1 {
        line-height: 1.4em;
        max-width: 780px;
    }
    span {
        padding: 0 4px;
    }
    span:first-child {
        background-color: ${pinkRed};
    }
    span:nth-child(2) {
        background-color: ${sepia};
        color: ${spaceCadet};
    }
    span:nth-child(3) {
        background-color: ${themeBlue};
        color: ${spaceCadet};
    }
`

const IndexPage = () => {
    return (
        <Page title="Daniel Martinez">
            <section className={hero}>
                <h1>
                    I'm <span>Daniel Martinez</span>, a{' '}
                    <span>Software Engineer</span> keen on developing world
                    class <span>digital products.</span>
                </h1>
            </section>
        </Page>
    )
}

export default IndexPage
